<script setup lang="ts">
import ChannelService from '~/libs/ChannelService.js';

import Utility from '~/services/Utility';

const router = useRouter();

const { openTallyPopup } = useTally();

const { onClick, ...attrs } = useAttrs();
// 반응형으로 동작해야 하는 속성은 여기서 삭제 한다.
delete attrs.class;

// NuxtLink 에서도 target="_blank" 속성이 있으면 새창으로 연다. 이것을 구현해야 한다.
// https://nuxt.com/docs/api/components/nuxt-link#nuxtlink 참조
const handleClick = async (event) => {
  // console.log('CustomLink handleClick', event);

  const nuxtLinkEl = event.target.tagName === 'A' ? event.target : event.target.closest('a');

  const { href, target } = nuxtLinkEl;
  const linkType = target === '_blank' ? 'N' : 'P';

  // https://tally.so/ 로 오면 탈리를 실행한다.
  if ($url.isTallyUrl(href)) {
    openTallyPopup(href);
    return;
  }
  // 채널톡으로 오면 채널톡을 실행한다.
  if ($url.isChannelUrl(href)) {
    const workflowId = href.split('/').find((v: string) => !isNaN(parseInt(v)));
    ChannelService.openWorkflow(workflowId);
    return;
  }
  // 월부 닷컴인경우 처리
  if ($url.isWeolbuUrl(href) || $url.isLocalHost(href)) {
    const url = $url.replaceWeolbuUrl(href, linkType === 'N');
    if (!url) {
      console.error('replaceWeolbuUrl is NULL !!!', href);
      return;
    }
    // 로그인이 필요한 페이지 처리
    if (url.startsWith('/mypage')) {
      // 로그인 처리
      if (!(await Utility.checkLoginThenRedirect())) {
        return;
      }
    }

    if (linkType === 'N') {
      Utility.openLink(url, linkType);
    } else {
      router.push(url);
    }

    return;
  }

  // 다른 도메인 이거나 /class 처럼 패스로만 오는경우 처리'
  Utility.openLink(href, linkType);
};
</script>
<template>
  <NuxtLink
    v-bind="attrs"
    @click.capture.prevent.stop="
      (event) => {
        // console.log('click.capture.prevent click', event);
        // NuxtLink 의 기본 동작을 막고 직접 처리할 수 있음
        onClick?.(event);
        // NuxtLink 의 기본 동작은 라우터 이동이므로 여기서 라우터 이동을 처리할 수 있음
        handleClick(event);
      }
    ">
    <slot></slot>
  </NuxtLink>
</template>
